//@ts-ignore
import App from './App'
import React from "react";
import * as ReactDOMClient from "react-dom";
import reactToWebComponent from "react-to-webcomponent";
import PropTypes from "prop-types"

//@ts-ignore
const wcLogin: any = reactToWebComponent(App, React, ReactDOMClient, {
    props: {
        serviceId: PropTypes.string.isRequired,
        clientId: PropTypes.string.isRequired,
        loginRedirectUrl: PropTypes.string.isRequired,
        logoutRedirectUrl: PropTypes.string.isRequired,
        env: PropTypes.string.isRequired,
        loginType: PropTypes.string.isRequired,
        pollingInterval: PropTypes.string.isRequired,
        redirectInterval: PropTypes.string.isRequired,
        authorityUrl: PropTypes.string.isRequired,
        signUpSignInAuthority: PropTypes.string.isRequired,
        forgotPasswordPolicyName: PropTypes.string.isRequired,
        labelButton: PropTypes.string.isRequired,
        targetServiceId: PropTypes.string.isRequired,
        contractVersion: PropTypes.string.isRequired,
        loginMenus: PropTypes.string.isRequired,
        editProfile: PropTypes.string.isRequired,
    },
})
customElements.define('wc-login', wcLogin)
