import { MouseEventHandler } from "react"
import CustomButton from './CustomButton';

interface IModalConfirmation {
   modal: {
    title: string,
    isShow: boolean,
    message: string
   },
   setOpen: Function,
   onSubmit: MouseEventHandler<HTMLDivElement>,
   btnTittleCancel?: string,
   btnTittleYes?: string
}

const ModalConfirmation = ({ modal, setOpen, onSubmit, btnTittleCancel, btnTittleYes }: IModalConfirmation) => {
  
  return (
        <div className='style-modal-create-users'>
          <div className={`modal-confirm ${modal?.isShow ? 'modal-block' : 'modal-hide'}`}>
            <div className="cube-login-modal-content">
            <p className="confirmation-title">{modal.title}</p>
            <p className='mb-l'>{modal?.message}</p>
                <div className="d-flex flex-row justify-right mt-l">
                <CustomButton variant="outlined" title={btnTittleCancel || "Cancel"} onClick={() => setOpen({...modal, isShow: false})} />
                <CustomButton variant="text" onClick={onSubmit} title={btnTittleYes || "Yes"} />
                </div>
            </div>
          </div>

        </div>
  );
};

export default ModalConfirmation;