export const Spinner = ({ value }) => {
  return (
    <>
      <div className="sqpp-spinner-container">
        <div className="sqpp-spinner">
          <div className="sqpp-spinner-text">{value}</div>
        </div>
      </div>
    </>
  );
};