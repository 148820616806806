import Button from '../component/Button'
import useAuth from '../hooks/useAuth'
import ProfileDropdown from './ProfileDropdown'

const Login = (props) => {
    const {
        authenticated,
        signInHandler,
        user,
        signOutHandler,
        field,
        setField,
        setUser,
        isValid,
    } = useAuth({ ...props })

    return (
        <div>
            {authenticated === null && !isValid && (
                <Button
                    label={props?.labelButton || 'Login'}
                    onClick={signInHandler}
                />
            )}
            {authenticated && isValid && (
                <ProfileDropdown
                    signOutHandler={signOutHandler}
                    setUser={setUser}
                    user={user}
                    loginMenus={JSON.parse(props?.loginMenus)}
                    field={field}
                    setField={setField}
                    targetServiceId={props?.targetServiceId}
                    editProfile={props?.editProfile}
                />
            )}
        </div>
    )
}

export default Login
