import { client, auth } from "cubeforall";

export const fieldHandlers = ({ name, value, field, setField }) => {
    const newField = { ...field };
    if (name === 'Email') {
      newField[name] = { ...newField[name], value, errMsg: '' };
      setField(newField);
    } else {
      newField[name] = { ...newField[name], value, errMsg: '' };
      setField(newField);
    }
  };

export const updateProfile = ({name, user, targetServiceId, setIsEdit, setUser, setShowSpinner, setOpenModalConfirmation, company}) => {
    // setIsEdit(false) 
    setOpenModalConfirmation(false) 
    setShowSpinner(true);
    const payload = {...user, company, name}

    auth.guard()
      .then( async () => {
          client
            .sendRequestAsync(
                targetServiceId,
                'Profile',
                '1',
                'UpdateProfile',
                payload
            )
            .then((res) => res.data)
            .then((res) => {
              let resData = res
              if(typeof res === 'string') resData = JSON.parse(res)
                setUser({...resData, name: resData?.name, email: resData?.email})
                setShowSpinner(false)
                setIsEdit(false) 
            })
            .catch((err) => {
                console.log(err);
            });           
      })
      .catch(() => {
          location.reload();
      })
}