import { useState, useEffect } from 'react'
import { init, auth, subscription, client, getToken } from 'cubeforall'
import moment from 'moment'
import useForm from './useForm'

const exceptionServiceID = [
    '2ccbe74c-493b-47a4-8dee-80bdb147e895',
    'ae8aec0e-048e-4ca2-95a6-c9d60de81b4d',
    '49f2eb0d-6c11-493d-b436-9701631b586a',
]

const useAuth = ({
    serviceId,
    clientId,
    env,
    loginRedirectUrl,
    logoutRedirectUrl,
    authorityUrl,
    signUpSignInAuthority,
    forgotPasswordPolicyName,
    loginType,
    setLoading,
    targetServiceId,
    contractVersion,
    pollingInterval,
    redirectInterval,
}) => {
    const [authenticated, setAuthenticated] = useState<any>()
    const [user, setUser] = useState({})

    const [initFields] = useState({
        value: '',
        errMsg: '',
        required: false,
        label: '',
        name: '',
    })

    const { field, setField } = useForm({
        Name: { ...initFields, required: true, label: 'Name' },
        Company: { ...initFields, label: 'Company' },
    })

    const isExcluded = exceptionServiceID.includes(serviceId)

    const signOutHandler = async () => {
        await auth.signOut()
        setAuthenticated(null)
        localStorage.setItem('isSubscribed', 'false')
        localStorage.removeItem('detailUser')
        document.cookie = null
        window.location.href = logoutRedirectUrl
    }

    const checkStatus = async (userData) => {
        const status = await subscription.checkSubscription()

        // if (!status.status || status?.productToken === null) {
        //     alert(
        //         'You do not have permission to access to SIA Cargo Partners Portal. Please contact your local station for more information.'
        //     )
        //     signOutHandler()
        // } else {
        localStorage.setItem('isSubscribed', 'true')
        setAuthenticated(userData)
        localStorage.setItem('role', JSON.stringify(status))
        if (status?.productToken?.hasOwnProperty('AgentCode')) {
            localStorage.setItem(
                'detailUser',
                JSON.stringify(status?.productToken)
            )
        } else {
            localStorage.removeItem('detailUser')
        }
        // }
    }

    const signInHandler = async () => {
        let isNewUser = false
        try {
            await auth.signIn().then(async (auth) => {
                if (auth) {
                    setTimeout(() => {
                        // if (!isExcluded) {
                        checkStatus(auth)
                            .then(() => {
                                const localData = localStorage.getItem('role')
                                const userStatus = JSON.parse(localData)
                                isNewUser = userStatus?.isNewUser
                                localStorage.setItem('isSubscribed', 'true')
                                setAuthenticated(auth)
                            })
                            .then(() => {
                                console.log('test isNewUser ?', isNewUser)
                                window.location.href =
                                    isNewUser === true
                                        ? '/manage-company'
                                        : loginRedirectUrl
                            })
                        // } else {
                        //     localStorage.setItem('isSubscribed', 'true')
                        //     setAuthenticated(auth)
                        //     window.location.href = loginRedirectUrl
                        // }
                    }, redirectInterval)
                }
            })
        } catch (e) {
            console.log({ signInHandler: e })
        }
    }

    const getUserPersistHandler = () => {
        const userPersist = localStorage.getItem('userPersist')
        return JSON.parse(userPersist)
    }

    const setUserPersistHandler = (data) => {
        localStorage.setItem('userPersist', JSON.stringify(data))
    }

    const getProfile = async (email) => {
        const userPersist = getUserPersistHandler()

        if (userPersist) {
            setUser(userPersist)
            setField({ ...field, Name: { value: userPersist?.name } })
            return
        }

        if (email) {
            await client
                .sendRequestAsync(
                    targetServiceId,
                    'Profile',
                    contractVersion,
                    'GetProfile',
                    { email }
                )
                .then((res) => res.data)
                .then((res) => {
                    let resData = res
                    if (typeof res === 'string') resData = JSON.parse(res)
                    setUserPersistHandler(resData)
                    setUser(resData)
                    setField({ ...field, Name: { value: resData.name } })
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const [isValid, setIsValid] = useState(true)

    useEffect(() => {
        const getInit = () => {
            return init(
                serviceId,
                clientId,
                env,
                loginType,
                authorityUrl,
                signUpSignInAuthority,
                forgotPasswordPolicyName
            )
        }
        const checkValidation = () => {
            auth.guard()
                .then(async (res) => {
                    const user = getInit()
                    setIsValid(true)
                    if (!isExcluded && !authenticated) checkStatus(res)
                    if (user) {
                        const currentWindowLocation = window.location.pathname
                        setAuthenticated(user)
                        const status = await subscription.checkSubscription()
                        console.log('checkIsNewUser ?', status)
                        if (
                            status?.isNewUser === true &&
                            !currentWindowLocation.includes('manage-company')
                        ) {
                            window.location.href = '/manage-company'
                        }
                    }
                })
                .catch((err) => {
                    console.log({ err })
                    setIsValid(false)
                    setAuthenticated(null)
                    return
                })
        }
        getInit()
        checkValidation()
    }, [])

    useEffect(() => {
        if (authenticated) getProfile(authenticated?.idTokenClaims?.email)
    }, [authenticated])
    return {
        authenticated,
        signInHandler,
        user,
        setUser,
        signOutHandler,
        field,
        setField,
        isValid,
    }
}

export default useAuth
