import {
    IPublicClientApplication,
    PublicClientApplication,
    BrowserCacheLocation,
} from '@azure/msal-browser'
import { useEffect } from 'react';
import { environment } from './environment'

export const MSALInstanceFactory = (props) => {

    const { clientId, redirectUrl, protectedResource, authorityDomain, authRequestScoped, signUpSignInAuthority } = props;

    return new PublicClientApplication({
        auth: {
            clientId: clientId,
            authority: signUpSignInAuthority,
            redirectUri: redirectUrl,
            postLogoutRedirectUri: '/',
            knownAuthorities: [authorityDomain],
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie:
                window.navigator.userAgent.indexOf('MSIE ') > -1 ||
                window.navigator.userAgent.indexOf('Trident/') > -1, // set to true for IE 11
        },
    })
}
