import { MouseEventHandler } from "react"

interface ICustomButton {
    onClick: MouseEventHandler<HTMLDivElement>,
    title: string,
    variant?: string,
    className?: string,
}

const CustomButton = ({ onClick, title, variant, className }: ICustomButton) => {
    return(
        <div className={` pointer custom-button-style ${className} ${variant === 'outlined' ? 'btn-bg-white' : 'btn-bg-blue'}`} onClick={onClick}>
            {title}
        </div>
    )
}

export default CustomButton