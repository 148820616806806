interface ICustomTextField  {
  name: string,
  value: any,
  disabled: boolean,
  fieldHandlers?: Function,
  placeholder?: string,
  errorMsg?: string,
  field?: {
    Name: {
        value: string
    },
    Company: {
        value: string
    }
 },
 setField?: Function,
}

const CustomTextField = ({ name, value, disabled, fieldHandlers, placeholder, errorMsg, field, setField }: ICustomTextField) => {
  const onChangeHandler = (e) => {
    const {name, value} = e.target
    fieldHandlers({name, value, field, setField})
  }
  return <div className='styled-component'>
    <div className={`input-container transform-border align-center`}>
      <input
        type='text'
        name={name}
        value={value || ''}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChangeHandler}
        className='w-100 outline'
      />
    </div>
    {errorMsg && <p className='error-msg'>{errorMsg}</p>}
    
  </div>
};

export default CustomTextField;
