import './styles/index.scss'
import { BrowserRouter } from 'react-router-dom'
import { MSALInstanceFactory } from './azureb2c'
import { MsalProvider } from '@azure/msal-react'
import Login from './view/Login'

const App = (props) => {
    const hash = location.href.lastIndexOf('#state=')
    const hashFireFox = window.location.href.lastIndexOf('#state=')
    
    if (hash >= 0 || hashFireFox >= 0) {
        return false
    } else {
        return (
            <BrowserRouter>
                <MsalProvider instance={MSALInstanceFactory(props)}>
                    <Login {...props} />
                </MsalProvider>
            </BrowserRouter>
        )
    }
}

export default App
