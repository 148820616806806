import { useState, useRef, useEffect } from 'react'
import pencil from '../assets/icon/edit.svg'
import EditProfile from './EditProfile'

const ProfileDropdown = ({
    signOutHandler,
    user,
    loginMenus,
    field,
    setField,
    targetServiceId,
    setUser,
    editProfile,
}) => {
    const [openDropdown, setOpendropdown] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editValue, setIsEditValue] = useState(false)
    const includeRole = [
        'L0 Headquarter Admin',
        'L0 Headquarter Member',
        'L0 Station Admin',
        'L0 Station Member',
    ]
    const role = JSON.parse(localStorage.getItem('role'))

    const pickColorAvatar = (name) => {
        const red = ['A', 'B', 'C', 'D', 'E', '0', '3', '5']
        const purple = ['F', 'G', 'H', 'I', 'J', '1', '4']
        const blueSky = ['K', 'L', 'M', 'N', 'O', '2', '7']
        const orange = ['P', 'Q', 'R', 'S', 'T', '6', '9']
        const pink = ['U', 'V', 'W', 'X', 'Y', 'Z', '8']

        if (red.includes(name)) return 'red'
        if (purple.includes(name)) return 'purple'
        if (blueSky.includes(name)) return 'blueSky'
        if (orange.includes(name)) return 'orange'
        if (pink.includes(name)) return 'pink'
    }

    const signOut = () => {
        signOutHandler(false)
        signOutHandler()
    }

    const ref_popup_profile = useRef()
    const onClickUserProfileHandler = () => {
        const searchHeaderFieldId = document.getElementById('qsearch')
        const closeIcon = document.getElementsByClassName(
            'btn-control-notext open'
        )

        if (searchHeaderFieldId !== null) {
            searchHeaderFieldId.classList.remove('opensub')
        }
        if (closeIcon.length > 0) {
            closeIcon[0].classList.remove('open')
        }
        setOpendropdown(!openDropdown)
    }
    useEffect(() => {
        const element: any = ref_popup_profile.current
        const checkIfClickedOutside = (e: any) => {
            if (element && !element.contains(e.target)) {
                setOpendropdown(false)
            }
        }
        document.addEventListener('click', checkIfClickedOutside)

        return () => {
            document.removeEventListener('click', checkIfClickedOutside)
        }
    }, [openDropdown])

    return (
        <div style={{ position: 'relative' }}>
            <div
                className={`circle-initial letter-avatar ${
                    'bg-' + pickColorAvatar(user?.name?.charAt(0).toUpperCase())
                }`}
                onClick={onClickUserProfileHandler}
            >
                {user?.name?.charAt(0).toUpperCase()}
            </div>

            {openDropdown && (
                <div className="animation-wrapper" ref={ref_popup_profile}>
                    <div className="name-wrapper">
                        <div className="profile-wrapper">
                            <div
                                className={`circle-initial ${
                                    'bg-' +
                                    pickColorAvatar(
                                        user?.name?.charAt(0).toUpperCase()
                                    )
                                }`}
                            >
                                {user?.name?.charAt(0).toUpperCase()}
                            </div>
                            <div className="name-container">
                                <p className="name">{user?.name}</p>
                                <p className="email">
                                    {user?.email?.toLowerCase()}
                                </p>
                            </div>
                        </div>
                        {editProfile !== 'false' && (
                            <div
                                className="pointer"
                                onClick={() => {
                                    setIsEdit(true)
                                }}
                            >
                                <img src={pencil} alt="edit" width={25} />
                            </div>
                        )}
                    </div>
                    {isEdit && (
                        <EditProfile
                            setIsEdit={setIsEdit}
                            field={field}
                            setField={setField}
                            user={user}
                            editValue={isEdit}
                            setIsEditValue={setIsEditValue}
                            targetServiceId={targetServiceId}
                            setUser={setUser}
                            role={role}
                        />
                    )}
                    {!isEdit && (
                        <div className="options-wrapper">
                            {loginMenus &&
                                loginMenus?.map((menu, idx) => {
                                    return (
                                        <div
                                            className="styled-option"
                                            key={idx}
                                            onClick={() =>
                                                setOpendropdown(false)
                                            }
                                        >
                                            <a href={menu?.baseUrl}>
                                                <span>{menu?.name}</span>
                                            </a>
                                        </div>
                                    )
                                })}
                            {includeRole?.includes(
                                role?.priceData?.nickname
                            ) && (
                                <div
                                    className="styled-option"
                                    onClick={() => setOpendropdown(false)}
                                >
                                    <a href="/portal_management/admin-setting">
                                        <span>Admin Setting</span>
                                    </a>
                                </div>
                            )}

                            <div
                                className="last-item pointer styled-option"
                                onClick={() => signOut()}
                            >
                                <span>Sign Out</span>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default ProfileDropdown
