import { AuthenticationResult } from '@azure/msal-browser'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    user: {},
    error: '',
    isLogin: false,
}

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

export const authReducer = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        LOGOUT_SUCCESS: (state) => {
            state.user = {}
            state.isLogin = false
        },
        LOGIN_SUCCESS: (state, data) => {
            state.isLogin = true
            state.user = {
                account: data.payload.account,
                accessToken: data.payload.accessToken,
                scopes: data.payload.scopes,
            }
        },
    },
})

export const { LOGIN_SUCCESS, LOGOUT_SUCCESS } = authReducer.actions
