import { useState } from 'react'
import CustomTextField from '../component/CustomTextField'
import CustomButton from '../component/CustomButton'
import { fieldHandlers, updateProfile } from '../hooks/useUpdateProfile'
import lock from '../assets/icon/lock.svg'
import ModalConfirmation from '../component/ModalConfirmation'
import { Spinner } from '../../src/component/Spinner'

interface IEditProfile {
    setIsEdit: Function
    field: {
        Name: {
            value: string
        }
        Company: {
            value: string
        }
    }
    user: {
        email: string
        name: string
    }
    setField: Function
    targetServiceId: string
    setUser: Function
    setIsEditValue: Function
    editValue: boolean
    role: {
        priceData: {
            nickname: string
        }
        productToken: {
            servicingStation: string
        }
    }
}

const EditProfile = ({
    setIsEdit,
    field,
    user,
    setField,
    targetServiceId,
    setUser,
    setIsEditValue,
    role,
}: IEditProfile) => {
    const [openModalCancelConfirmation, setOpenModalCancelConfirmation] =
        useState(false)
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const detailUser =
        localStorage.getItem('detailUser') !== null
            ? JSON.parse(localStorage.getItem('detailUser'))
            : {}
    const cancelHandler = () => {
        setField({ ...field, Name: { value: user?.name } })
        setIsEdit(false)
        setIsEditValue(false)
    }

    return (
        <div className="form-wrapper">
            <div style={{ paddingRight: '30px' }}>
                <p className="label">Name</p>
                <CustomTextField
                    name="Name"
                    value={field?.Name?.value}
                    field={field}
                    setField={setField}
                    fieldHandlers={fieldHandlers}
                    disabled={false}
                    placeholder="Name"
                />
            </div>
            <div>
                <p className="label">Email</p>
                <div className="d-flex">
                    <CustomTextField
                        name="Email"
                        value={user?.email}
                        disabled
                    />
                    <div className="lock-icon">
                        <img src={lock} alt="edit" width={20} />
                    </div>
                </div>
            </div>

            {(role?.priceData?.nickname === 'L0 Station Admin' ||
                role?.priceData?.nickname === 'L0 Station Member') && (
                <div>
                    <p className="label">Servicing Station</p>
                    <div className="d-flex">
                        <CustomTextField
                            name="Email"
                            value={role?.productToken?.servicingStation || '-'}
                            disabled
                        />
                        <div className="lock-icon">
                            <img src={lock} alt="edit" width={20} />
                        </div>
                    </div>
                </div>
            )}

            {Object.keys(detailUser).length > 0 && (
                <div>
                    <div>
                        <p className="label">Customer Name</p>
                        <div className="d-flex">
                            <CustomTextField
                                name="Customer Name"
                                value={detailUser?.AgentName || '-'}
                                disabled
                            />
                            <div className="lock-icon">
                                <img src={lock} alt="edit" width={20} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="label">Customer Code</p>
                        <div className="d-flex">
                            <CustomTextField
                                name="Customer Code"
                                value={detailUser?.AgentCode || '-'}
                                disabled
                            />
                            <div className="lock-icon">
                                <img src={lock} alt="edit" width={20} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="label">Station</p>
                        <div className="d-flex">
                            <CustomTextField
                                name="Station"
                                value={detailUser?.station || '-'}
                                disabled
                            />
                            <div className="lock-icon">
                                <img src={lock} alt="edit" width={20} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="label">SQ Servicing Station</p>
                        <div className="d-flex">
                            <CustomTextField
                                name="Station"
                                value={detailUser?.servicingStation || '-'}
                                disabled
                            />
                            <div className="lock-icon">
                                <img src={lock} alt="edit" width={20} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="label">Privilege Role</p>
                        <div className="d-flex">
                            <CustomTextField
                                name="Privilege Role"
                                value={role?.priceData?.nickname || '-'}
                                disabled
                            />
                            <div className="lock-icon">
                                <img src={lock} alt="edit" width={20} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="btn-wrapper">
                <CustomButton
                    onClick={() => setOpenModalCancelConfirmation(true)}
                    variant="outlined"
                    title="CANCEL"
                />
                <CustomButton
                    title="UPDATE"
                    onClick={() => setOpenModalConfirmation(true)}
                />
            </div>

            <ModalConfirmation
                modal={{
                    message: 'Are you sure you want to discard your changes?',
                    isShow: openModalCancelConfirmation,
                    title: 'Discard Changes?',
                }}
                setOpen={() => setOpenModalCancelConfirmation(false)}
                onSubmit={() => cancelHandler()}
            />

            <ModalConfirmation
                modal={{
                    message: 'Are you sure you want to update your profile?',
                    isShow: openModalConfirmation,
                    title: 'Are you sure?',
                }}
                setOpen={() => setOpenModalConfirmation(false)}
                onSubmit={() => {
                    updateProfile({
                        name: field?.Name?.value,
                        user,
                        targetServiceId,
                        setIsEdit,
                        setUser,
                        setShowSpinner,
                        setOpenModalConfirmation,
                        company:
                            detailUser?.AgentName != '' &&
                            detailUser?.AgentName != undefined
                                ? detailUser?.AgentName
                                : user?.name,
                    })
                    ;() => setIsEditValue(false)
                }}
            />

            {showSpinner && <Spinner value={''} />}
        </div>
    )
}

export default EditProfile
