import { useState } from 'react';

export default function useForm(initialField) {
  const [field, setField] = useState(initialField || {});

  const setFieldValues = ({ name, value, errMsg }) => {
    const newField = {...field}
    setField({
      ...newField,
      [`${name}`]: { ...field[`${name}`], value, errMsg},
    })
  };

  return {
    field,
    setField,
    setFieldValues
  };
}